<template>
  <Form />
</template>

<script>
import Form from "../components/Form.vue";

export default {
  name: "Home",
  components: {
    Form,
  },
};
</script>

<style lang="sass"></style>
