<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container d-flex flex-column">
          <h3>Tratamiento de Datos Personales para otros usos</h3>
          <div class="modal-body">
            <p>
              Adicionalmente, usted autoriza a COMPAÑÍA HARD DISCOUNT S.A.C.
              para que transfiera vuestros datos a las empresas que se
              identifican en el
              <a
                href="https://registratusdatos.tiendasmass.com.pe/registro/politicasdeprivacidadydatos/"
                target="_BLANK"
                >enlace</a
              >, a efectos que dichas empresas realicen para sí mismas los
              tratamientos indicados en el ítem precedente (cabe indicar que,
              según se indica en el referido enlace, algunas de dichas empresas
              cuentan con servidores informáticos situados fuera del Perú, por
              lo que se producirá una transferencia internacional de datos).
            </p>
          </div>
          <button class="btn-close-modal" @click="$emit('close')">
            <b-icon icon="x" font-scale="1"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalPersonalInfo",
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {
    this.$body.classList.add("modal-open");
  },
  destroyed() {
    this.$body.classList.remove("modal-open");
  },
};
</script>

<style lang="sass">
.modal-open
  overflow: hidden

.modal-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.modal-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.modal-container
    position: relative
    width: 90%
    margin: 0px auto
    padding: 50px 20px
    background-color: #fff
    border-radius: 2%
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    background: linear-gradient(180deg, #1f71b1, #274975)
    transition: all .3s ease
    box-sizing: border-box

    h3
      font-size: 20px
      margin-bottom: 20px
      color: #fff
      font-family: 'Omnes Semibold'
      padding: 0 16px

    .modal-body
        display: inline-block
        overflow: auto
        padding: 0 16px !important

        a
          font-family: 'Omnes Semibold'
          color: #fff

        p
            font-size: 9px
            color: #fff
            font-family: 'Omnes Medium'
            text-align: justify
            line-height: 115%
            margin: 0

    .modal-body::-webkit-scrollbar
        background-color: rgba(0, 0, 0, 0)
        width: 6px

    .modal-body::-webkit-scrollbar-track
        background-color: rgba(0, 0, 0, 0)


    .modal-body::-webkit-scrollbar-thumb
        background-color: #fff
        border-radius: 3px

    .btn-close-modal
        width: 24px
        height: 24px
        border: none
        position: absolute
        top: 10px
        right: 10px
        color: $blue
        background-color: #fff
        border-radius: 50%

        svg
          width: 20px
          height: 20px
          vertical-align: -0.25em !important

@media (min-width: 768px)
    .modal-container
        width: 30%

        h3
            font-size: 30px

        .modal-body
            p
              font-size: 12px
              line-height: 120%

@media (min-width: 992px)

@media (min-width: 1200px)
</style>
