<template>
  <transition name="modal-authorization">
    <div class="modal-authorization-mask">
      <div class="modal-authorization-wrapper">
        <div class="modal-authorization-container d-flex flex-column">
          <h3>Autorizo compartir mis datos con empresasde intercorp</h3>
          <div class="modal-authorization-body">
            <p>
              Usted autoriza a Compañía Hard Discount S.A.C. para que:<br /><br />
              Transfiera vuestros datos a las empresas que se identifican en el
              enlace, a efectos de que estas realicen para sí mismas el
              tratamiento de sus datos para gestionar listas de clientes,
              realizar estudios de mercado y evaluaciones financieras, registrar
              y analizar historiales de compra y elaborar perfiles de compra,
              efectuar acciones de publicidad y prospección comercial,
              ofrecimiento de promociones comerciales, fines estadísticos o
              históricos, comercio electrónico, remitir (vía medio físico,
              electrónico o telefónico) publicidad, obsequios, información de
              ofertas y/o promociones (personalizadas o generales) de productos
              y/o servicios.<br /><br />
              Cabe indicar que algunas de dichas empresas cuentan con servidores
              informáticos situados fuera del Perú, por lo que se producirá una
              transferencia internacional de datos.
            </p>
          </div>
          <button class="btn-close-modal" @click="$emit('close')">
            <b-icon icon="x" font-scale="1"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalPersonalInfo",
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {
    this.$body.classList.add("modal-open");
  },
  destroyed() {
    this.$body.classList.remove("modal-open");
  },
};
</script>

<style lang="sass">
.modal-open
  overflow: hidden

.modal-authorization-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.modal-authorization-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.modal-authorization-container
    position: relative
    width: 90%
    margin: 0px auto
    padding: 50px 20px
    background-color: #fff
    border-radius: 2%
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    background: linear-gradient(180deg, #1f71b1, #274975)
    transition: all .3s ease
    box-sizing: border-box

    h3
      font-size: 20px
      margin-bottom: 20px
      color: #fff
      font-family: 'Omnes Semibold'
      padding: 0 16px

    .modal-authorization-body
        display: inline-block
        overflow: auto
        padding: 0 16px !important

        a
          font-family: 'Omnes Semibold'
          color: #fff

        p
            font-size: 9px
            color: #fff
            font-family: 'Omnes Medium'
            text-align: justify
            line-height: 115%
            margin: 0

    .modal-authorization-body::-webkit-scrollbar
        background-color: rgba(0, 0, 0, 0)
        width: 6px

    .modal-authorization-body::-webkit-scrollbar-track
        background-color: rgba(0, 0, 0, 0)


    .modal-authorization-body::-webkit-scrollbar-thumb
        background-color: #fff
        border-radius: 3px

    .btn-close-modal
        width: 24px
        height: 24px
        border: none
        position: absolute
        top: 10px
        right: 10px
        color: $blue
        background-color: #fff
        border-radius: 50%

        svg
          width: 20px
          height: 20px
          vertical-align: -0.25em !important

@media (min-width: 768px)
    .modal-authorization-container
        width: 30%

        h3
            font-size: 30px

        .modal-authorization-body
            p
              font-size: 12px
              line-height: 120%

@media (min-width: 992px)

@media (min-width: 1200px)
</style>
