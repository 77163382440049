<template>
  <div class="loader-mask">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {};
  },
};
</script>

<style lang="sass">

.loader-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.loader-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.loader
    border: 6px solid #f3f3f3
    border-radius: 50%
    border-top: 6px solid $yellow
    width: 60px
    height: 60px
    -webkit-animation: spin 2s linear infinite
    animation: spin 2s linear infinite


/* Safari */
@-webkit-keyframes spin
    0%
        -webkit-transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
</style>
