<template>
  <div class="navbar container-fluid">
    <div class="container px-4 px-md-5">
      <div
        class="col-12 d-flex flex-row align-items-end justify-content-between justify-content-md-start py-3"
      >
        <a href="https://www.tiendasmass.com.pe/">
          <img src="@/assets/logo-mass.svg" alt="Logo Mass" />
        </a>
        <p>Los mejores precios, cerca de ti</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="sass">
.navbar
    background-color: $yellow

    a img
        width: 140px

    p
      max-width: 50%
      font-size: 16px
      line-height: 100%
      text-align: left
      font-family: 'Omnes Semibold'
      color: $blue
      margin: 0

@media (min-width: 768px)
  .navbar
    img
      margin-right: 20px
    p
      font-size: 22px
</style>
