<template>
  <transition name="modal">
    <div class="modal-form-mask">
      <div class="modal-form-wrapper">
        <div class="modal-form-container d-flex flex-column">
          <h3>¡Registro exitoso, caserit@!</h3>
          <div class="modal-form-body">
            <p>Gracias por tu tiempo.</p>
          </div>
          <button class="btn-close-modal-form" @click="$emit('close')">
            <b-icon icon="x" font-scale="1"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {
    this.$body.classList.add("modal-open");
  },
  destroyed() {
    this.$body.classList.remove("modal-open");
  },
};
</script>

<style lang="sass">
.modal-form-open
  overflow: hidden

.modal-form-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.modal-form-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.modal-form-container
    position: relative
    width: auto
    height: auto
    margin: 0px auto
    padding: 50px 20px
    background-color: #fff
    border-radius: 2%
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    background: linear-gradient(180deg, #1f71b1, #274975)
    transition: all .3s ease
    box-sizing: border-box

    h3
      font-size: 22px
      margin-bottom: 20px
      color: #fff
      font-family: 'Omnes Semibold'
      margin: 0
      text-align: center

.modal-form-body
    display: inline-block
    overflow: auto
    padding: 0 16px !important

    p
        font-size: 22px
        color: #fff
        font-family: 'Omnes Medium'
        text-align: justify
        line-height: 115%
        margin: 0
        text-align: center

.modal-form-body::-webkit-scrollbar
    background-color: rgba(0, 0, 0, 0)
    width: 6px

.modal-form-body::-webkit-scrollbar-track
    background-color: rgba(0, 0, 0, 0)


.modal-form-body::-webkit-scrollbar-thumb
    background-color: #fff
    border-radius: 3px

.btn-close-modal-form
    width: 24px
    height: 24px
    border: none
    position: absolute
    top: 10px
    right: 10px
    color: $blue
    background-color: #fff
    border-radius: 50%

    svg
      width: 20px
      height: 20px
      vertical-align: -0.25em !important

@media (min-width: 768px)
    .modal-form-container
        padding: 80px 40px
        h3
            font-size: 30px

        .modal-form-body
            p
                font-size: 24px


@media (min-width: 992px)

@media (min-width: 1200px)
</style>
