<template>
  <transition name="modal-terms">
    <div class="modal-terms-mask">
      <div class="modal-terms-wrapper">
        <div class="modal-terms-container d-flex flex-column">
          <h3>Términos y condiciones</h3>
          <div class="modal-terms-body">
            <p><span>Sorteo:</span> “La lista Mass feliz” por la Campaña de Aniversario</p><br/>
            <p>
              <span><u>1. Quiénes pueden participar en el sorteo:</u></span>
              <br/>
              Podrán participar en el sorteo las personas naturales mayores de edad que sean clientes de MASS 
              en cualquier de las tiendas de Lima metropolitana Sólo participan personas que realicen compras 
              en tienda física a partir de S/15.00 (Quince con 00/100) en cualquier producto y con cualquier 
              método de pago aceptado en tiendas MASS entre el 16 al 30 de septiembre del 2023 y que en dicho 
              plazo registren sus datos y comprobantes de compra en: 
              <a href="http://www.registratusdatos.tiendamass.com.pe" target="_blank">www.registratusdatos.tiendamass.com.pe</a>
            </p>
            <br />
            <p>
              <span><u>2. Vigencia y ámbito territorial:</u></span>
              <br/>
              La vigencia de la promoción es desde el 16 de setiembre al 30 de setiembre de 2023 
              en Lima metropolitana donde MASS tenga tiendas físicas.
            </p>
            <br />
            <p>
              <span><u>3. Fecha, lugar y hora del sorteo:</u></span>
              <br/>
              El sorteo se realizará el 05 de octubre de 2023 en el transcurso del día, usando sistemas y equipos electrónicos. 
              Se publicará la lista de ganadores en Facebook e Instagram, dentro de la semana o una semana posterior de 
              realizado el sorteo.
            </p>
            <br />
            <p>
              <span><u>4. Premio:</u></span>
              <br/>
              Como premios serán 08 cupos para participar en “La lista Mass feliz”, en donde cada uno de los ganadores 
              de esta opción tendrá 1 minuto para completar en una pizarra un cuadro en el cual incluye la siguiente información:
              <br/>
              <span>Cantidad:</span> El cliente deberá escribir el número de productos que desea siendo 3 el máximo permitido.<br/>
              <span>Producto:</span> El cliente deberá escribir de manera legible el nombre del producto en la lista.<br/>
              <span>Marca:</span> El cliente deberá escribir de manera legible la marca del producto que desea incluir en su lista.<br/>
              <span>Peso:</span> El cliente deberá escribir de manera legible el peso que corresponde al producto, ejemplo: 00 kg, 00 gr, 00lt, 00ml<br />
              <img src="@/assets/cuadro.png" alt="" class="" />
              <br />
              El cliente se hará acreedor únicamente de los productos que hayan descrito en la pizarra completando todos los casilleros 
              (cantidad, producto, marca, precio) en 1 minuto de participación.<br/>
              De no existir stock del producto, marca o peso que el participante haya colocado en su pizarra dentro del minuto, 
              podrá seleccionar un producto sustito que se encuentre en stock de tienda. La lista o el premio en ningún caso podrá 
              superar el valor máximo de S/ 400.00 (Ver mecánica).
              <br />
              <br />
              <span>Premios:</span> Serán 08 cupos para participar en “La lista Mass feliz”. <br />
              <span>Valor máximo de “La lista Mass feliz”</span> Máximo S/ 400.00.<br /><br />
              No más de un premio por ganador. Solo una opción de participar en la “La lista Mass feliz”.<br />
              Todos los premios son en Lima Metropolitana.<br />
              La Fecha y el lugar de la realización de “La lista Mass feliz” serán definidos 
              por MASS (Compañía Hard Discount SAC) a su discrecionalidad dentro de los 30 días 
              de realizado el sorteo, salvo caso no previsto, en donde la actividad podrá 
              ser reprogramada, lo que será informado a los ganadores. Mass podrá definir a su 
              discrecionalidad más de una fecha y lugar de actividad para realizar “La lista Mass feliz” 
              en Lima metropolitana.
            </p>
            <br />
            <p>
              <span><u>5. Mecánica del sorteo:</u></span>
              <br />
              El Cliente de Mass que realice compras en tienda física a partir de S/ 15.00 
              (Quince con 00/100 Soles) en cualquier producto y con cualquier método de pago 
              aceptado en tiendas MASS entre el 16 al 30 de septiembre del 2023 y que en dicho 
              plazo registren sus datos y comprobantes de compra en: 
              <a href="http://www.registratusdatos.tiendamass.com.pe" target="_blank">www.registratusdatos.tiendamass.com.pe</a>
              recibe 1 (una) opción para participar en el sorteo.<br /><br />
              Los ganadores del sorteo tendrán la posibilidad de participar de la “La lista Mass feliz”, 
              realizado por la campaña de Aniversario que consiste en llevarse todos los productos que 
              pueda describir en una lista hasta un máximo de S/ 400.00 en 1 minuto, conforme los 
              presentes términos y condiciones.
            </p>
            <br />
            <p>
              <span><u>6. Restricción del sorteo:</u></span>
              <br />
              <ul>
                <li>Un mismo participante no podrá ser acreedor de más de un premio durante la vigencia de la promoción.</li>
                <li>Ser mayor de edad.</li>
                <li>Los premios no podrán cambiarse por otros, ni se podrá canjear por efectivo.</li>
                <li>Los premios son intransferibles.</li>
                <li>No podrá participar del sorteo los colaboradores de COMPAÑIA HARD DISCOUNT SAC.</li>
                <li>Los productos adquiridos durante “La lista Mass feliz” no incluyen despacho.</li>
                <li>Si los productos anotados exceden los S/ 400, solo se consideran productos hasta por este valor. Todos los productos en tienda incluyen IGV.</li>
                <li>Serán 8 ganadores en Lima metropolitana en donde Mass tenga tiendas Físicas.</li>
              </ul>
              <span><u>PARA LA ENTREGA DE PREMIOS:</u></span>
              <ul>
                <li>Después del sorteo se contactará a cada cliente ganador. Sin perjuicio de publicitar a los ganadores dentro de los 4 días posteriores a cada sorteo en Facebook e Instagram.</li>
                <li>Se coordinará con cada cliente la fecha para su participación en “La lista Mass feliz” </li>
                <li>De no asistir el día indicado a la actividad para realizar la mecánica de “La Lista Mass Feliz” el ganador del sorteo perderá su premio y por ende la opción de participar.</li>
                <li>Los participantes deberán llegar a la hora indicada el día de “La lista Mass feliz” de lo contrario perderán su premio y por ende la opción de participar.</li>
              </ul>
            </p>
            <br />
            <p>
              <span><u>7. Obligaciones de los participantes:</u></span>
              <ul>
                <li>Los que participan declaran hacerlo bajo su propia responsabilidad y aseguran conocer y aceptar las condiciones de la participación en la promoción y el uso de sus datos personales.</li>
                <li>Los datos del(a) consumidor(a) participante deben ser reales y comprobables. Cualquier dato que no pueda ser verificado implicará la imposibilidad de continuar participando en el sorteo.</li>
                <li>Los ganadores autorizan a COMPAÑIA HARD DISCOUNT SAC y sus afiliadas a conservar sus datos personales y compartirlos con la empresa para efectos de su participación en la presente promoción y poder hacer la entrega del premio correspondiente. La autorización es necesaria para la ejecución de la finalidad aquí descrita.</li>
                <li>Los ganadores autorizan a COMPAÑIA HARD DISCOUNT SAC para que publiquen y/o utilicen sus datos personales y/o uso de imagen para comunicar los resultados del sorteo y/o concurso ya sea en medios masivos tales como televisión, radio, redes sociales y prensa o a nivel de los puntos de ventas.</li>
              </ul>
            </p>
            <p>
              <span><u>8. Procedimiento para selección y contacto con los ganadores:</u></span>
              <br />
              El sorteo se realizará el 05 de octubre del 2023 durante el transcurso del día, en presencia de 
              representantes de las empresas COMPAÑIA HARD DISCOUNT SAC. y de Notario Público. Usando el sistema 
              y equipos electrónicos de Mass.<br /><br />
              El mismo día del sorteo, inmediatamente de realizarse el acto, se llamará al número telefónico 
              del potencial ganador sorteado de la base de datos vinculada a las compras, por un máximo 
              de 3 (tres) veces, la cual será constatada por el Notario presente; y en caso de no responder 
              el potencial ganador, en ninguna de las oportunidades, se anulará de inmediato la opción 
              electrónica potencialmente ganadora; y se sorteará a otra persona participante, realizándose 
              el mismo procedimiento anterior. Se contactará a tantas personas como sean necesarias para 
              declarar a los 08 ganadores. Se publicará la lista de ganadores dentro de los 7 días posteriores 
              al sorteo y se concederá al ganador un plazo de 7 días calendario contados desde dicha publicación 
              para reclamar su premio, caso contrario, el premio se dejará sin efecto.<br /><br />
              <span><u>Otorgamiento de los premios:</u></span>
              <span><u>PARA LOS GANADORES DE LA CIUDAD DE LIMA:</u></span>
              <ul>
                <li>Los ganadores del sorteo serán agrupados y comunicados por Mass vía Instagram y Facebook en un plazo no mayor a 7 días calendarios posteriores a las fechas de sorteo.</li>
                <li>Mass o quien designe, se comunicará vía telefónica con cada ganador y se verificará los datos de los mismos para la realización de las actas respectivas de sorteo y entregas de premio (en caso corresponda).</li>
                <li>El ganador tendrá un plazo máximo de 7 días calendarios posteriores a la publicación de los ganadores, para reclamar su premio, caso contrario quedará sin efecto.</li>
                <li>Mass o quien designe se encargará de coordinar con cada ganador su participación en la “La lista Mass feliz”</li>
                <li>Para realizar el otorgamiento de los premios a los ganadores; esto es, hacer válida su participación en “La lista Mass feliz”, estos deberán presentar obligatoriamente su DNI o carnet de extranjería original y una fotocopia simple.</li>
                <li>Si el cliente ganador no pudiera recibir el premio, lo podrá realizar a través de un representante, quien deberá portar una Carta Poder, certificada notarialmente, con una copia de su DNI o carnet de extranjería y también copia del DNI o carnet de extranjería del Cliente Ganador, pero el premio estará destinado al titular.</li>
                <li>Al momento de la entrega del premio, los ganadores deberán firmar la autorización que se encuentra en el Anexo 2.</li>
                <li>Sin perjuicio de toda acción judicial y con la facultad de rescindir la inscripción del usuario, COMPAÑIA HARD DISCOUNT SAC no está obligada a entregar un premio a todo usuario que no haya introducido correctamente sus datos personales en el momento del registro o que no se atenga a los presentes Términos y condiciones.</li>
                <li>Los premios mostrados en este sorteo no incluyen ningún otro beneficio adicional como: transporte o despacho al domicilio del cliente, todo esto es responsabilidad del cliente ganador.</li>
                <li>Los premios no serán, bajo ningún aspecto, canjeables en dinero o de cualquier otra forma modificable o transferible por el BENEFICIARIO.</li>
              </ul>
            </p>
            <p>
              <span><u>9. Comunicación Publicitaria:</u></span>
              <br />
              El presente sorteo podrá ser informado a través de personal impulsador, comercial de televisión, 
              radio, publicidad on line, página web, volantes, material pop y/o cualquier otro medio de difusión 
              a criterio de COMPAÑIA HARD DISCOUNT SAC
            </p>
            <p>
              <span><u>10. Autorización de datos personales:</u></span>
              <br />
              El ganador autoriza a la Compañía Hard Discount S.A.C y sus afiliadas y/o empresas del Grupo Intercorp 
              a usar y conservar sus datos personales y compartirlos con la empresa para efectos de su participación 
              en la presente promoción y poder hacer la entrega del premio correspondiente. La autorización 
              es necesaria para la ejecución de la finalidad aquí descrita.<br /><br />
              El ganador autoriza a la Compañía Hard Discount SAC para que publiquen y/o utilicen sus datos personales 
              y/o uso de imagen para comunicar los resultados del sorteo ya sea en medios masivos tales como televisión, 
              radio, redes sociales, prensa, entre otras.<br /><br />
              Los participantes brindan su autorización para el uso y/o conservación de sus datos personales. 
              Al momento de la entrega del premio, el ganador/ganadora deberá regularizar firmando la autorización 
              que se encuentra en el Anexo 2.<br /><br />
            </p>
            <p>
              <span>Consideraciones:</span><br>
              MASS se reserva el derecho de modificar estos Términos y Condiciones si lo considera necesario.
            </p>
          </div>
          <button class="btn-close-modal" @click="$emit('close')">
            <b-icon icon="x" font-scale="1"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalTerms",
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {
    this.$body.classList.add("modal-open");
  },
  destroyed() {
    this.$body.classList.remove("modal-open");
  },
};
</script>

<style lang="sass">
.modal-open
  overflow: hidden

.modal-terms-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.modal-terms-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.modal-terms-container
    position: relative
    width: 90%
    height: 60%
    margin: 0px auto
    padding: 50px 20px
    background-color: #fff
    border-radius: 2%
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    background: linear-gradient(180deg, #1f71b1, #274975)
    transition: all .3s ease
    box-sizing: border-box

    h3
      font-size: 20px
      margin-bottom: 20px
      color: #fff
      font-family: 'Omnes Semibold'
      padding: 0 16px

    .modal-terms-body
        display: inline-block
        overflow: auto
        padding: 0 16px !important

        a
          font-family: 'Omnes Semibold'
          color: #fff

        p
            font-size: 9px
            color: #fff
            font-family: 'Omnes Medium'
            text-align: justify
            line-height: 115%
            margin: 0

            img
              display: block
              margin: 5px auto
              width: 100%

            span
                font-family: 'Omnes Semibold'

    .modal-terms-body::-webkit-scrollbar
        background-color: rgba(0, 0, 0, 0)
        width: 6px

    .modal-terms-body::-webkit-scrollbar-track
        background-color: rgba(0, 0, 0, 0)


    .modal-terms-body::-webkit-scrollbar-thumb
        background-color: #fff
        border-radius: 3px

    .btn-close-modal
        width: 24px
        height: 24px
        border: none
        position: absolute
        top: 10px
        right: 10px
        color: $blue
        background-color: #fff
        border-radius: 50%

        svg
          width: 20px
          height: 20px
          vertical-align: -0.25em !important

@media (min-width: 768px)
    .modal-terms-container
        width: 40%
        height: 70%

        h3
            font-size: 30px

        .modal-terms-body
            p
              font-size: 12px
              line-height: 130%

              img
                display: block
                width: 250px

@media (min-width: 992px)

@media (min-width: 1200px)
</style>
