<template>
  <div class="contenedor-principal p-3">
    <loading v-if="showLoader" />
    <modal-privacy v-if="showModal" @close="showModal = !showModal" />
    <modal-form v-if="showModalForm" @close="showModalForm = !showModalForm" />
    <modal-document v-if="showModalDoc" @close="showModalDoc = !showModalDoc" />
    <modal-personal-info
      v-if="showModalPersonalInfo"
      @close="showModalPersonalInfo = !showModalPersonalInfo"
    />
    <modal-terms
      v-if="showModalTerms"
      @close="showModalTerms = !showModalTerms"
    />
    <modal-authorization
      v-if="showModalAuthorization"
      @close="showModalAuthorization = !showModalAuthorization"
    />
    <div
      class="contenedor-form h-100 d-flex flex-column flex-md-row align-items-md-center"
    >
      <div
        class="img-container my-4 my-md-0 col-12 col-md-5 col-lg-6 d-flex flex-column align-items-center justify-content-center"
      >
        <img src="@/assets/SorteoLanding_CashCard-mobile.png" alt="" class="d-md-none" />
        <img src="@/assets/SorteoLanding_CashCard.png" alt="" class="d-none d-md-block" />
      </div>

      <div
        class="col-12 col-md-7 col-lg-6 shadow-lg bg-white rounded-3 form-container my-4 my-md-0 py-4 px-3 d-flex flex-column justify-content-evenly"
      >
        <h2>Bienvenido</h2>
        <h3>¡Déjanos aquí tus datos de<br />contacto, caserit@!</h3>
        <b-form
          id="form"
          @submit.prevent="sendInfo"
          class="d-flex flex-wrap justify-content-center all-inputs"
          :class="{ form__alert: styleForm }"
        >
          <b-form-group
            label="Nombres*"
            label-for="nombres"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-input
              id="nombres"
              v-model="form.nombres"
              class="msg__alert"
            ></b-form-input>
            <small>{{ errors.nombres }}</small>
          </b-form-group>

          <b-form-group
            label="Apellidos*"
            label-for="apellidos"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-input
              id="apellidos"
              v-model="form.apellidos"
              class="msg__alert"
            ></b-form-input>
            <small>{{ errors.apellidos }}</small>
          </b-form-group>

          <b-form-group
            label="Tipo de documento*"
            label-for="documento"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-select
              id="documento"
              v-model="form.tipoDoc"
              :options="documentTypes"
              class="w-100"
              :class="{ msg__alert: !form.tipoDoc }"
            ></b-form-select>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group
            label="Nro. de documento*"
            label-for="numeroDoc"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-input
              id="numeroDoc"
              v-model="form.numeroDoc"
              type="number"
              class="msg__alert"
            ></b-form-input>
            <small>{{ errors.numeroDoc }}</small>
          </b-form-group>

          <b-form-group
            label="Correo electrónico*"
            label-for="email"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-input
              type="email"
              id="email"
              v-model="form.email"
              :class="{ msg__alert: !form.email }"
            ></b-form-input>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group
            label="Celular*"
            label-for="celular"
            class="col-6 p-2 d-flex flex-column align-items-start align-self-end form__group"
          >
            <b-form-input
              id="celular"
              v-model="form.celular"
              type="number"
              :class="{ msg__alert: !form.celular }"
            ></b-form-input>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group
            label="Departamento*"
            label-for="departmento"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-select
              id="departmento"
              v-model="form.departamento"
              :options="departamentos"
              class="w-100"
              @change="onChangeDep"
              :class="{ msg__alert: !form.departamento }"
            ></b-form-select>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group
            label="Provincia*"
            label-for="provincia"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-select
              id="provincia"
              v-model="form.provincia"
              :options="provincias"
              class="w-100"
              @change="onChangeProv"
              :class="{ msg__alert: !form.provincia }"
            ></b-form-select>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group
            label="Distrito*"
            label-for="distrito"
            class="col-6 p-2 d-flex flex-column align-items-start form__group"
          >
            <b-form-select
              id="distrito"
              v-model="form.distrito"
              :options="distritos"
              class="w-100"
              :class="{ msg__alert: !form.distrito }"
            ></b-form-select>
            <small>Completa este campo</small>
          </b-form-group>

          <!-- CODIGO BOLETA DE VENTA AA11-12345678 -->

          <b-form-group
            label="Código boleta de venta*"
            label-for="boleta"
            class="col-12 p-2 mb-3 d-flex flex-column align-items-start align-self-end form__group"
          >
            <div
              class="d-flex flex-row gap-1 align-items-center" 
              :class="{ msg__alert: this.validateCode(this.codeComplete).valid === false }"
            >
                <b-form-input
                  id="boletaA"
                  v-model="form.boletaA"
                  maxlength="4"
                  style="width: 35%;"
                ></b-form-input>
              -
                <b-form-input
                  id="boletaB"
                  v-model="form.boletaB"
                  maxlength="8"
                  style="width: 65%;"
                ></b-form-input>
            </div>
            <small>{{ errors.code }}</small>
          </b-form-group>

          <b-form-group class="col-12 px-sm-0 px-md-5 mb-3 form__group">
            <b-form-checkbox
              id="checkbox"
              v-model="form.politicaDatos"
              name="checkbox"
              class="checkbox-terms d-flex"
              :class="{ msg__alert: !form.politicaDatos }"
              >He leído y acepto las
              <a @click.prevent="showModal = !showModal">
                políticas de privacidad y protección de datos.
              </a>
            </b-form-checkbox>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group class="col-12 px-sm-0 px-md-5 mb-3">
            <b-form-checkbox
              id="checkboxPersonalInfo"
              v-model="form.usoAdicional"
              name="checkboxPersonalInfo"
              class="checkbox-terms d-flex"
              >He leído y acepto la autorización del
              <a
                @click.prevent="showModalPersonalInfo = !showModalPersonalInfo"
              >
                tratamiento de datos personales para usos adicionales.
              </a>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="col-12 px-sm-0 px-md-5 mb-3 form__group">
            <b-form-checkbox
              id="checkboxAutorizacion"
              v-model="form.autorizacion"
              name="checkboxAutorizacion"
              class="checkbox-terms d-flex"
              :class="{ msg__alert: !form.autorizacion }"
            >
              <a
                @click.prevent="
                  showModalAuthorization = !showModalAuthorization
                "
              >
                Autorizo compartir mis datos con la empresa Intercorp
              </a>
            </b-form-checkbox>
            <small>Completa este campo</small>
          </b-form-group>

          <b-form-group class="terms-conditions col-12 px-sm-0 px-md-5 mb-3">
            <p class="m-0">
              Revisa los términos y condiciones del sorteo
              <a @click.prevent="showModalTerms = !showModalTerms">AQUÍ</a>
            </p>
          </b-form-group>

          <b-form-group
            class="col-12 mb-3 d-flex flex-column justify-content-center form__group__checkbox"
            :class="{ recaptcha__failed: !recaptcha }"
          >
            <vue-recaptcha
              sitekey="6LeJJnUdAAAAAPACMlL8GB-A0vGIsyiO6c9ghntf"
              :loadRecaptchaScript="true"
              @verify="onVerify"
              ref="recaptchaRobot"
            ></vue-recaptcha>
            <small>Marca la opción</small>
          </b-form-group>

          <b-button class="btn-submit rounded-pill" type="submit"
            >Registrarme</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import ModalPrivacy from "./ModalPrivacy.vue";
import ModalForm from "./ModalForm.vue";
import ModalPersonalInfo from "./ModalPersonalInfo.vue";
import ModalTerms from "./ModalTerms.vue";
import ModalDocument from "./ModalDocument.vue";
import ModalAuthorization from "./ModalAuthorization.vue";
import Loading from "./Loading.vue";

// import tiendasMass from "../data/tiendas-mass.json";
import tiendasActualizadas from "../data/departamentos.json";

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Form",
  components: {
    ModalPrivacy,
    ModalForm,
    ModalDocument,
    ModalPersonalInfo,
    ModalTerms,
    ModalAuthorization,
    VueRecaptcha,
    Loading,
  },
  data() {
    return {
      form: {
        nombres: null,
        apellidos: null,
        tipoDoc: null,
        numeroDoc: null,
        departamento: null,
        provincia: null,
        distrito: null,
        email: null,
        celular: null,
        boletaA: null,
        boletaB: null,
        politicaDatos: false,
        usoAdicional: false,
        autorizacion: false,
      },
      documentTypes: [
        { text: "Por favor seleccione", value: null },
        { text: "DNI", value: "DNI" },
        { text: "CE", value: "CE" },
        { text: "Pasaporte", value: "Pasaporte" },
      ],
      departamentos: [{ text: "Por favor seleccione", value: null }],
      provincias: [{ text: "Por favor seleccione", value: null }],
      distritos: [{ text: "Por favor seleccione", value: null }],
      showModal: false,
      showModalForm: false,
      showModalDoc: false,
      showModalPersonalInfo: false,
      showModalTerms: false,
      showModalAuthorization: false,
      users: [],
      recaptcha: null,
      styleForm: false,
      showLoader: false,
      errors: {
        nombres: "",
        apellidos: "",
        numeroDoc: "",
        code: "",
      },
      valid: true,
      url: "",
    };
  },
  created() {},
  computed: {
    codeComplete: function (){
      return this.form.boletaA + "-" + this.form.boletaB;
    }
  },
  mounted() {
    this.getDepartmentos();
    this.onChangeDep();
    this.onChangeProv();
    this.urlRedirect();
  },
  methods: {
    urlRedirect() {
      if (window.location.href == "https://mass-registro.iz.pe/") {
        console.log("Se usa enlace de QA");
        this.url = "https://mass-registro-adm.iz.pe/registro.php";
      } else if (window.location.href == "https://registratusdatos.tiendasmass.com.pe/") {
        console.log("Se usa enlace de Produccion");
        this.url = "https://reporteador-mass.tiendasmass.com.pe/registro.php";
      }
      console.log(this.url);
    },
    validateName(name) {
      if (!name) {
        return { valid: false, error: "Completa este campo" };
      } else if (!name.match(/^(?![\s'-]+$)[a-zA-Z\u00C0-\u017F\s'-]+$/)) {
        return { valid: false, error: "Ingresa caracteres válidos" };
      } else {
        return { valid: true, error: null };
      }
    },
    validateDoc(number) {
      if (!number) {
        return { valid: false, error: "Completa este campo" };
      } else if (number.length < 8) {
        return {
          valid: false,
          error: "Ingrese un número válido",
        };
      } else {
        return { valid: true, error: null };
      }
    },
    validateCode(code) {
      if (this.form.boletaA == null && this.form.boletaB == null) {
        return { valid: false, error: "Completa este campo" };
      } else if (!code.match(/^[a-zA-Z]{2}[0-9]{2}(?:-[0-9]{8})?$/)) {
        return {
          valid: false,
          error: "Ingrese un código de 4 dígitos (2 letras y 2 números), seguido de 8 números.",
        };
      } else if (code.match(/^[a-zA-Z]{2}[0-9]{2}(?:-[0-9]{8})?$/)) {
        return { valid: true, error: null };
      }
    },
    getDepartmentos() {
      const arr = [];
      tiendasActualizadas.map((x) => {
        arr.push(x.departamento);
      });
      const dep = [...new Set(arr)];
      dep.sort();
      dep.map((x) => {
        this.departamentos.push(x);
      });
    },
    onChangeDep() {
      const arrSelected = [];
      tiendasActualizadas.map((x) => {
        if (this.form.departamento === x.departamento) {
          arrSelected.push(x);
          this.form.provincia = null;
          this.form.distrito = null;
          this.provincias = [{ text: "Por favor seleccione", value: null }];
          this.distritos = [{ text: "Por favor seleccione", value: null }];
          return arrSelected;
        }
      });
      const arr = [];
      arrSelected.map((x) => {
        arr.push(x.provincias);
      });
      const prov = [...new Set(arr)];
      prov.sort();
      prov.map((x) => {
        x.map((y)=>{
          this.provincias.push(y.provincia);
        })
      });
    },
    onChangeProv() {
      const arrNew = []
      tiendasActualizadas.map((x) => {
        const arr = []
        arr.push(x.provincias)
        arr.map((y)=>{
          y.map((z)=>{
            arrNew.push(z)
          })
        })
      });

      this.form.distrito = null;
      this.distritos = [{ text: "Por favor seleccione", value: null }];

      arrNew.map((m)=>{
        if (this.form.provincia === m.provincia){
          const arr = []
          arr.push(m.distritos)
          arr.map((x)=>{
            x.map((y)=>{
              this.distritos.push(y.distrito)
            })
          })
        }
      })
    },
    cleanForm() {
      (this.form.nombres = null),
        (this.form.apellidos = null),
        (this.form.tipoDoc = null),
        (this.form.numeroDoc = null),
        (this.form.departamento = null),
        (this.form.provincia = null),
        (this.form.distrito = null),
        (this.form.email = null),
        (this.form.celular = null),
        (this.form.boletaA =  null),
        (this.form.boletaB =  null),
        (this.form.politicaDatos = false),
        (this.form.usoAdicional = false),
        (this.form.autorizacion = false);
    },
    onVerify(res) {
      this.recaptcha = res;
    },
    sendInfo() {
      this.errors = {};

      const validNombre = this.validateName(this.form.nombres);
      this.errors.nombres = validNombre.error;

      const validApellido = this.validateName(this.form.apellidos);
      this.errors.apellidos = validApellido.error;

      const validDoc = this.validateDoc(this.form.numeroDoc);
      this.errors.numeroDoc = validDoc.error;

      const validCode = this.validateCode(this.codeComplete);
      this.errors.code = validCode.error;

      if (
        validNombre.valid &&
        validApellido.valid &&
        this.form.tipoDoc &&
        validDoc.valid &&
        this.form.departamento &&
        this.form.provincia &&
        this.form.distrito &&
        this.form.email &&
        this.form.celular &&
        validCode.valid &&
        this.form.politicaDatos == true &&
        this.form.autorizacion == true &&
        this.recaptcha
      ) {
        const self = this;
        setTimeout(() => {
          self.showLoader = true;
        }, 0);

        let polDatosStr = "";
        let usoAdicionalStr = "";

        if (this.form.politicaDatos == 1) {
          polDatosStr = "accepted";
        } else {
          polDatosStr = "not_accepted";
        }

        if (this.form.usoAdicional == 1) {
          usoAdicionalStr = "accepted";
        } else {
          usoAdicionalStr = "not_accepted";
        }

        const objUser = {
          nombres: this.form.nombres,
          apellidos: this.form.apellidos,
          tipoDoc: this.form.tipoDoc,
          numeroDoc: this.form.numeroDoc.toString(),
          departamento: this.form.departamento,
          provincia: this.form.provincia,
          distrito: this.form.distrito,
          email: this.form.email,
          celular: this.form.celular,
          boleta: this.codeComplete.toString(),
          fecha: new Date().toLocaleString(),
          politicaDatos: polDatosStr,
          usoAdicional: usoAdicionalStr,
          utmSource: this.$route.query.utm_source,
          utmMedium: this.$route.query.utm_source,
          utmCampaign: this.$route.query.utm_campaign,
          utmContent: this.$route.query.utm_content,
          codRecaptcha: this.recaptcha,
        };

        console.log(objUser)

        fetch(this.url, {
          method: "POST",
          body: JSON.stringify(objUser),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (res == 1) {
              this.cleanForm();
              this.recaptcha = null;
              this.styleForm = false;
              this.$refs.recaptchaRobot.reset();
              this.showLoader = false;
              this.showModalForm = true;
            } else {
              this.showLoader = false;
              this.showModalDoc = true;
            }
          });
      } else {
        this.styleForm = true;
      }
    },
  },
};
</script>

<style lang="sass">
small
  position: absolute
  display: none
  color: red
  font-size: 10px !important
  text-align: left

.form__group
  position: relative

.form__group__checkbox div
  position: relative

.form__alert .form__group .msg__alert ~ small
  display: block

.form__alert .recaptcha__failed small
  display: block

.contenedor-principal
    padding-left: 0
    padding-right: 0
    width: 100%
    margin: 0
    background-image: url("../assets/SorteoLanding_CashCard-fondo-MOBILE.png")
    background-position: center center
    background-repeat: no-repeat
    background-size: cover

    .img-container
      img
        width: 90%

    .form-container
      h2
        font-family: 'Omnes Semibold'
        color: $blue
        line-height: 100%

      h3
        font-family: 'Omnes Medium'
        font-size: 16px
        color: $blue

      .form-group
        div
          width: 100% !important

      .all-inputs
        label
          font-size: 12px
          font-family: 'Omnes Medium'
          color: $blue

        select
          border: none
          background-color: $grey-input
          font-family: 'Omnes Medium'
          color: $blue
          font-size: 12px
          height: 36px
          padding: 0.375rem 0.75rem

          option
            font-size: 12px

        input
          font-family: 'Omnes Medium'
          border: none
          background-color: $grey-input
          color: $blue

        input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
          -webkit-appearance: none
          margin: 0

        .checkbox-terms
          input
            margin-top: 4px

          label
            margin-left: 6px
            font-size: 12px
            text-align: left

          a
            font-family: 'Omnes Semibold'
            color: $blue
            cursor: pointer
            text-decoration: underline

        .terms-conditions
          font-size: 12px
          font-family: "Omnes Medium"
          color: $blue

          p a
            font-family: "Omnes Semibold"
            cursor: pointer
            text-decoration: underline

      .btn-submit
        font-family: 'Omnes Semibold'
        border: none
        padding: 4px 8px
        background-color: $yellow
        color: $blue

@media (min-width: 768px)
  .contenedor-principal
    height: 1000px
    align-items: center
    background-image: url("../assets/SorteoLanding_CashCard-fondo.png")
    background-position: top center

    .img-container
      img
        width: 70%

    .form-container
      width: 400px
      z-index: 3

@media (min-width: 1200px)
  .contenedor-principal

    .contenedor-form
      max-width: 1200px
      margin: 0 auto

      .form-container
        width: 450px

@media (min-width: 1600px)
  .contenedor-principal
    background-size: cover
</style>
