<template>
  <transition name="modal-privacy">
    <div class="modal-privacy-mask">
      <div class="modal-privacy-wrapper">
        <div class="modal-privacy-container d-flex flex-column">
          <h3>Políticas de Datos Personales</h3>
          <div class="modal-privacy-body">
            <p>
              En el marco de la Ley N° 29733 (Ley de Protección de Datos Personales) y su reglamento aprobado por Decreto Supremo N° 003-2013-JUS (“Reglamento”), te comunicamos lo siguiente:<br /><br />
              1.- El titular del banco de datos personales donde se almacenarán tus datos personales es Compañía Hard Discount S.A.C., con RUC N° 20608280333y domicilio en Calle Morelli N° 181, San Borja, Lima. Dicho banco de datos se encuentra debidamente inscrito en el Registro Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos, bajo la denominación “Clientes”.<br /><br />
              2.- Tus datos personales serán tratados para las siguientes finalidades:<br /><br />
              Participar de sorteos, gestionar listas de clientes, realizar estudios de mercado y evaluaciones financieras, registrar y analizar historiales de compra y elaborar perfiles de compra, efectuar acciones de publicidad y prospección comercial, ofrecimiento de promociones comerciales, análisis estadístico para la elaboración de estrategias comerciales, obtención de información a través de fuentes públicas y privadas, comercio electrónico, remitir (vía medio físico, electrónico o telefónico) publicidad, obsequios, información de ofertas y/o promociones (personalizadas o generales) de productos y/o servicios.<br /><br />
              Adicionalmente, en virtud del principio de calidad y a fin de mantener tu información actualizada para el cumplimiento de la finalidad señalada en el presente documento, Agora podrá obtener tus datos personales de manera lícita a través de información recibida de terceros o que sea obtenida de fuentes públicas y lícitas. En esa línea, esta información podrá ser utilizada para el cumplimiento de dicha finalidad así como transferida a los terceros identificados en este documento para cumplir dicha finalidad.<br /><br />
              Cabe indicar que para realizar las referidas finalidades podremos valernos de los terceros (encargados de tratamiento) que apreciarás haciendo clic en el siguiente
              <a
                href="https://registratusdatos.tiendasmass.com.pe/sorteo/proveedores/"
                target="_BLANK"
                >enlace</a
              >. Según se indica en el referido enlace, algunos de dichos terceros se encuentran situados fuera del Perú, por lo que se producirá una transferencia internacional de datos.<br /><br />
              3.- Entre los datos que te pedimos, algunos son de carácter obligatorio; en caso decidas no proporcionarlos, no será posible gestionar tu registro ni realizar los tratamientos indicados en los numerales 2. Al ingresar tus datos, declaras y certificas que ellos corresponden a ti y que son verdaderos, exactos, auténticos, completos, y correctos; y que eres mayor de edad.<br /><br />
              4.- Asimismo te comunicamos que la autorización que nos brindas nos faculta para tratar (únicamente para las finalidades antes indicadas) no sólo los datos que nos has proporcionado directamente, sino también aquellos que pudieran encontrarse en fuentes accesibles para el público y/o que hayamos obtenidos de terceros; incluidos datos sensibles, de ser el caso.<br /><br />
              5.- Conservaremos tus datos personales mientras no nos solicites la cancelación de los mismos, en cuyo caso procederemos conforme al segundo párrafo del artículo 2.2 del Reglamento. Compañía Hard Discount S.A.C. tiene implementadas las medidas técnicas, organizativas y legales que garantizan la seguridad y confidencialidad de tus datos personales.<br /><br />
              6.- En cualquier momento podrás revocar la autorización para el tratamiento de tus datos personales, de conformidad con lo previsto en la Ley 29733 y el Reglamento. Para ejercer este derecho, o cualquier otro previsto en dichas normas, deberás presentar tu solicitud en Calle Morelli N° 181, San Borja, Lima, o en cualquier tienda Mass. Cabe indicar que el ejercicio de algunos de esos derechos (como por ejemplo el de revocación de la autorización) traerá como consecuencia dejar sin efecto tu registro en nuestro banco de datos.
            </p>
          </div>
          <button class="btn-close-modal" @click="$emit('close')">
            <b-icon icon="x" font-scale="1"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {
    this.$body.classList.add("modal-open");
  },
  destroyed() {
    this.$body.classList.remove("modal-open");
  },
};
</script>

<style lang="sass">
.modal-open
  overflow: hidden

.modal-privacy-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    transition: opacity .3s ease

.modal-privacy-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.modal-privacy-container
    position: relative
    width: 90%
    height: 60%
    margin: 0px auto
    padding: 50px 20px
    background-color: #fff
    border-radius: 2%
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    background: linear-gradient(180deg, #1f71b1, #274975)
    transition: all .3s ease
    box-sizing: border-box

    h3
      font-size: 24px
      margin-bottom: 20px
      color: #fff
      font-family: 'Omnes Semibold'

.modal-privacy-body
    display: inline-block
    overflow: auto
    padding: 0 16px !important

    a
        font-family: 'Omnes Semibold'
        color: #fff

    p
        font-size: 9px
        color: #fff
        font-family: 'Omnes Medium'
        text-align: justify
        line-height: 115%
        margin: 0

.modal-privacy-body::-webkit-scrollbar
    background-color: rgba(0, 0, 0, 0)
    width: 6px

.modal-privacy-body::-webkit-scrollbar-track
    background-color: rgba(0, 0, 0, 0)


.modal-privacy-body::-webkit-scrollbar-thumb
    background-color: #fff
    border-radius: 3px

.btn-close-modal
    width: 24px
    height: 24px
    border: none
    position: absolute
    top: 10px
    right: 10px
    color: $blue
    background-color: #fff
    border-radius: 50%

    svg
      width: 20px
      height: 20px
      vertical-align: -0.25em !important

@media (min-width: 768px)
    .modal-privacy-container
        width: 40%
        height: 70%

        h3
            font-size: 32px

        .modal-privacy-body
            p
              font-size: 12px
              line-height: 120%

@media (min-width: 992px)

@media (min-width: 1200px)
</style>
