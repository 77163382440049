<template>
  <div class="container-prinicipal container-fluid p-0">
    <div class="all-container d-flex container-fluid p-0 justify-content-center">
      <div
        class="container-sm-payment d-flex flex-column align-items-start"
      >
        <div
          class="payment-types d-flex flex-column flex-md-row align-items-center justify-content-between justify-content-md-center"
        >
          <h3 class="align-self-start mb-0 align-self-md-center">MÉTODOS DE PAGO</h3>
          <div>
            <img src="@/assets/mdp_mob.webp" class="d-md-none img-mob" alt="" />
            <img src="@/assets/mdp_desk.webp" class="d-none d-md-flex img-desk" alt="" width="100%"/>
          </div>
        </div>
        <div
          class="info d-flex flex-column all-info"
        >
          <div class="d-flex flex-column">
            <div class="url-list d-flex flex-column flex-md-row justify-content-md-between">
              <div
                class="d-flex flex-column align-items-start each-info mb-lg-0"
              >
                <h3>CONÓCEME</h3>
                <div class="each-info-links d-md-flex flex-md-row flex-md-wrap mb-lg-5">
                  <a
                    href="https://www.tiendasmass.com.pe/conoceme/"
                    target="_blank"
                    ><h4>Conóceme</h4></a
                  >
                  <a
                    href="https://www.tiendasmass.com.pe/precios-mass/"
                    target="_blank"
                  >
                    <h4>Precios Mass</h4>
                  </a>
                  <a
                    href="https://www.tiendasmass.com.pe/ubicame/"
                    target="_blank"
                  >
                    <h4>Ubícame</h4>
                  </a>
                  <a
                    href="https://www.tiendasmass.com.pe/productos/"
                    target="_blank"
                    ><h4>Productos</h4></a
                  >
                  <a
                    href="https://forms.gle/5yfJ68HYUpngV9zu5"
                    target="_blank"
                    ><h4>Trabaja conmigo</h4></a
                  >
                  <a
                    href="https://ofrecetulocal.tiendasmass.com.pe/local/registro/1"
                    target="_blank"
                    ><h4>Alquila tu local</h4></a
                  >
                  <a
                    href="https://registratusdatos.tiendasmass.com.pe/"
                    target="_blank"
                    ><h4>Regístrate</h4></a
                  >
                </div>
                <div class="social-media d-none d-lg-flex flex-lg-row align-items-center">
                  <h3 class="pb-2 pb-md-0">SÍGUEME EN</h3>
                  <div
                    class="container-logos-rs d-flex"
                  >
                    <a 
                      href="https://www.facebook.com/TiendasMassPeru" 
                      target="_blank"
                      ><img src="../assets/facebook-color.webp" alt="facebook"
                    /></a>
                    <a 
                      href="https://www.instagram.com/tiendasmassperu/" 
                      target="_blank"
                      ><img src="../assets/instagram-color.webp" alt="instagram"
                    /></a>
                    <a
                      href="https://www.tiktok.com/@tiendasmassperu"
                      target="_blank"
                      ><img src="../assets/tiktok-color.webp" alt="tiktok"
                    /></a>
                    <a
                      href="https://www.youtube.com/@TiendasMass/"
                      target="_blank"
                      ><img src="../assets/youtube-color.webp" alt="youtube"
                    /></a>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column align-items-start servicio mb-lg-0"
              >
                <h3>SERVICIO AL CLIENTE</h3>
                <div class="info-text">
                  <h4>Horarios de atención:</h4>
                  <h4>De lunes a domingo de 7 AM a 10 PM</h4>
                </div>
                <a
                  href="mailto:servicioalcliente@spsa.pe"
                  target="_blank"
                  ><h4>servicioalcliente@spsa.pe</h4></a
                >
                <a
                  href=""
                  target="_blank"
                  ><h4>Políticas de cambios y devoluciones</h4></a
                >
                <a
                  href="https://www.tiendasmass.com.pe/tyc/"
                  target="_blank"
                  ><h4>Términos y condiciones</h4></a
                >
                <a
                  href="http://spsa.paperless.com.pe/ConsultaMass/"
                  target="_blank"
                  ><h4>Comprobante electrónico</h4></a
                >
              </div>
            </div>
          </div>
          <div class="social-media d-md-flex flex-md-row align-items-center d-lg-none">
            <h3 class="pb-2 pb-md-0">SÍGUEME EN</h3>
            <div
              class="container-logos-rs d-flex"
            >
              <a 
                href="https://www.facebook.com/TiendasMassPeru" 
                target="_blank"
                ><img src="../assets/facebook-color.webp" alt="facebook"
              /></a>
              <a 
                href="https://www.instagram.com/tiendasmassperu/" 
                target="_blank"
                ><img src="../assets/instagram-color.webp" alt="instagram"
              /></a>
              <a
                href="https://www.tiktok.com/@tiendasmassperu"
                target="_blank"
                ><img src="../assets/tiktok-color.webp" alt="tiktok"
              /></a>
              <a
                href="https://www.youtube.com/@TiendasMass/"
                target="_blank"
                ><img src="../assets/youtube-color.webp" alt="youtube"
              /></a>
            </div>
          </div>
          <p class="mt-4 mb-3 copyright">© Tiendas Mass 2023<br>Compañía Hard Discount S.A.C</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="sass">

.container-prinicipal
  background-color: #00e6d5

.container-sm-payment
  width: 100%
  padding: 54px 21px 24px 21px
  border-radius: 40px 40px 0 0
  background-color: #fff

  .payment-types
    width: 100%
    margin-bottom: 40px

    h3
      font-size: 24px
      font-family: 'Cocogoose Classic Black', sans-serif
      color: $blue
      line-height: 100%

    .img-mob
      width: 350px
      max-width: 100%

  .all-info
    width: 100%

    .url-list
      width: 100%

      .each-info
        text-align: left
        margin-bottom: 24px

        h3
          font-size: 20px
          font-family: 'Cocogoose Classic Black', sans-serif
          color: $blue
          line-height: 100%
          margin: 0
          margin-bottom: 10px

        .each-info-links
          a
            text-decoration: none

          h4
            font-family: 'Cocogoose Classic', sans-serif
            color: $blue
            font-size: 20px
            line-height: 100%
            margin-bottom: 10px

      .servicio
        text-align: left
        margin-bottom: 24px

        h3
          font-size: 20px
          font-family: 'Cocogoose Classic Black', sans-serif
          color: $blue
          line-height: 100%
          margin: 0
          margin-bottom: 10px

        .info-text
          margin-bottom: 16px

          h4
            font-family: 'Cocogoose Classic', sans-serif
            color: $blue
            font-size: 16px
            line-height: 100%
            margin-bottom: 10px

          p
            font-family: 'Cocogoose Classic', sans-serif
            font-size: 18px
            color: #4a4a4a

        a
          text-decoration: none

          h4
            font-family: 'Cocogoose Classic', sans-serif
            color: $blue
            font-size: 16px
            line-height: 100%
            margin-bottom: 10px

    .social-media
      h3
        font-size: 20px
        font-family: 'Cocogoose Classic Black', sans-serif
        color: $blue
        line-height: 100%
        margin-bottom: 0
        text-align: left

      .container-logos-rs
        a
          margin-right: 24px
        img
          height: 40px

.copyright
    font-family: 'Cocogoose Classic', sans-serif

    .container-marcas
      img
        width: 70%

      hr
        margin: 0
        border: none
        border-left: 1px solid #fff
        height: 14px
        opacity: .2

@media (min-width: 576px)
  .all-container
      background-color: #fff
      border-radius: 40px 40px 0 0

  .container-sm-payment
      width: 540px

      .payment-types
        .img-mob
          width: 350px

@media (min-width: 768px)
  .container-sm-payment
    width: 720px
    padding: 30px 0px 24px 0px

    .payment-types
      margin-bottom: 70px
      h3
        text-align: left

    .all-info
      .url-list
        .each-info
          .each-info-links
            a
              margin-right: 30px
            h4
              font-size: 20px

        .servicio
          .info-text
            h4
              font-size: 20px

            p
              font-size: 20px

          a
            h4
              font-size: 20px
      .social-media
        h3
          padding-right: 20px

@media (min-width: 992px)
  .container-sm-payment
      width: 960px
  

@media (min-width: 1200px)
  .container-sm-payment
      width: 1140px

@media (min-width: 1400px)
  .container-sm-payment
      width: 1320px

</style>
